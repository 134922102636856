.card-info {
    @media only screen and (max-width:720px) {
        display: flex;
        flex-direction: column;
    }

    display: flex;
    gap: var(--dui-spacing-large);
    overflow: auto;

    .heading-content {
        font-weight: bold;
    }

    .global-stat-card {
        flex-basis: 40%;
        flex-shrink: 0;
        border-radius: 0;
        /* By default, ContainerCard has border-radius */
        box-shadow: 0px 0px 10px var(--dui-card-box-shadow);
    }

    .regions-pie-chart {
        flex-grow: 1;
        border-radius: 0;
        /* By default, ContainerCard has border-radius */
        box-shadow: 0px 0px 10px var(--dui-card-box-shadow);
    }

    .responsive-content {
        overflow: unset;

        .pie-chart-collection {
            display: flex;
        }

        .responsive-container {
            min-height: 10rem !important;
            overflow: hidden;
        }
    }
}

.breakdown-label-wrapper {
    display: flex;
    justify-content: space-around;
    padding-top: var(--dui-spacing-medium);

    .breakdown-label {
        display: flex;
        gap: var(--dui-spacing-small);
        align-items: center;

        .label-name {
            font-size: var(--dui-font-size-medium);
        }
    }
}