.readiness-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    border: var(--width-separator-thin) solid transparent;
    border-radius: var(--dui-border-radius-card);
    box-shadow: 0px 0px 10px var(--dui-card-box-shadow);
    padding: var(--dui-spacing-medium);
    color: var(--dui-color-label);

    .readiness-title {
        font-size: var(--dui-font-size-heading-extra-small);
        font-weight: var(--dui-font-weight-regular);
    }

    .readiness-value {
        font-size: var(--dui-font-size-super-large);
        font-weight: var(--dui-font-weight-bold);
    }

    &.no-data {
        background-color: var(--dui-color-foreground);
    }

    &.green {
        background-color: var(--color-indicator-high);
    }

    &.yellow {
        background-color: var(--color-indicator-medium-high);
    }

    &.orange {
        background-color: var(--color-indicator-medium-low);
    }

    &.red {
        background-color: var(--color-indicator-low);
    }
}
