.country-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--dui-spacing-large);

    .heading {
        font-weight: bold;
    }

    .country-main {
        @media only screen and (max-width:1440px) {
            flex-wrap: wrap;
        }

        display: flex;
        gap: var(--dui-spacing-large);

        .country-detail-wrapper {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            flex-wrap: wrap;
            gap: var(--dui-spacing-large);
            overflow-x: auto;

            .status-card-container {
                display: flex;
                flex-direction: column;
                flex-shrink: 0;
                border-radius: 0;
                /* By default, ContainerCard has border-radius */
                background-color: var(--color-background);
                padding: 0;

                .status-container {
                    display: flex;
                    gap: var(--dui-spacing-large);
                    overflow-x: auto;

                    @media only screen and (max-width:720px) {
                        flex-wrap: wrap;
                    }

                    &.wrap-reversed {
                        flex-wrap: wrap-reverse;
                    }

                    .info-cards {
                        display: flex;
                        flex-shrink: 0;
                        flex-wrap: wrap-reverse;
                        gap: var(--dui-spacing-large);
                    }

                    .score-card {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        box-shadow: 0px 0px 10px var(--dui-card-box-shadow);
                        background-color: var(--dui-color-foreground);
                        padding: var(--dui-spacing-medium);
                        gap: var(--dui-spacing-large);

                        .score-heading {
                            flex-shrink: 0;
                            font-size: var(--dui-font-size-large);
                            font-weight: bold;
                        }

                        .score-footer {
                            flex-shrink: 0;
                            font-size: var(--dui-font-size-medium);
                            overflow-wrap: break-word;
                        }

                        .readiness-list-card {
                            display: flex;
                            flex-wrap: wrap;
                            gap: var(--dui-spacing-medium);
                        }
                    }
                }
            }

            .country-trend {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                border-radius: 0;
                /* By default, ContainerCard has border-radius */
                box-shadow: 0px 0px 10px var(--dui-card-box-shadow);
                width: 100%;
                overflow: auto;

                .responsive-content {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    min-height: 20rem;

                    .responsive-container {
                        display: flex;
                        flex-grow: 1;
                        justify-content: center;
                        overflow-y: hidden;

                        .tooltip-card {
                            display: flex;
                            flex-direction: column;
                            border: 1px solid var(--dui-color-separator);
                            box-shadow: 0px 0px 4px var(--dui-card-box-shadow);
                            background-color: var(--color-foreground);
                            padding: var(--dui-spacing-small) var(--dui-spacing-medium);
                            font-size: var(--dui-font-size-medium);
                            gap: var(--dui-spacing-small);

                            .tooltip-heading {
                                font-weight: var(--dui-font-weight-bold);
                            }

                            .tooltip-content {
                                color: var(--dui-color-description);
                                font-size: var(--dui-font-size-small);
                            }
                        }
                    }
                }
            }

            .indicator-wrapper {
                display: flex;
                flex-wrap: wrap;
                gap: var(--dui-spacing-large);

                .percentage-card {
                    flex-basis: calc(45% - var(--dui-spacing-large));
                    flex-grow: 1;
                    border-radius: 0;
                    /* By default, ContainerCard has border-radius */
                    box-shadow: 0px 0px 10px var(--dui-card-box-shadow);
                    overflow-y: auto;

                    .global-details {
                        display: flex;
                        gap: var(--dui-spacing-large);
                        overflow-y: auto;

                        .global-value-box {
                            .global-value {
                                display: flex;
                                align-items: center;
                                flex-basis: calc(40% - var(--dui-spacing-extra-large));
                                justify-content: center;
                                color: var(--color-text-secondary);
                                font-size: var(--dui-font-size-ultra-large);
                            }

                            .global-value-date {
                                text-align: right;
                                color: var(--dui-color-watermark);
                                font-size: var(--dui-font-size-extra-large);
                            }
                        }


                        .global-progress-bar {
                            flex-basis: calc(60% - var(--dui-spacing-extra-large));
                            flex-grow: 1;
                            padding: var(--dui-spacing-large);
                            height: 16rem;
                            overflow-y: hidden;
                            scrollbar-gutter: stable both-edges;

                            &:hover {
                                overflow-y: auto;
                            }
                        }
                    }
                }

                .indicators-chart {
                    flex-basis: calc(55% - var(--dui-spacing-small));
                    box-shadow: 0px 0px 10px var(--dui-card-box-shadow);
                }

                .gender-disaggregation {
                    flex-basis: calc(45% - var(--dui-spacing-large));
                    flex-grow: 1;
                    border-radius: 0;
                    /* By default, ContainerCard has border-radius */
                    box-shadow: 0px 0px 10px var(--dui-card-box-shadow);

                    .gender-disaggregation-content {
                        display: flex;
                        gap: var(--dui-spacing-large);

                        .gender-disaggregation {
                            display: flex;
                            flex-direction: column;
                            flex-grow: 1;
                            min-height: 22rem;
                            overflow: hidden;

                        }
                    }
                }

                .age-disaggregation {
                    flex-basis: calc(55% - var(--dui-spacing-small));
                    flex-grow: 1;
                    border-radius: 0;
                    /* By default, ContainerCard has border-radius */
                    box-shadow: 0px 0px 10px var(--dui-card-box-shadow);

                    .age-disaggregation-content {
                        display: flex;
                        gap: var(--dui-spacing-large);

                        .age-disaggregation {
                            display: flex;
                            flex-direction: column;
                            min-height: 22rem;
                            overflow: hidden;
                        }
                    }
                }
            }
        }

        .country-info {
            @media only screen and (max-width:1440px) {
                width: 100%;
            }

            flex-shrink: 0;
            border-radius: 0;
            /* By default, ContainerCard has border-radius */
            box-shadow: 0px 0px 10px var(--dui-card-box-shadow);
            background-color: var(--dui-color-foreground);
            min-width: var(--dui-width-min-modal);

            .country-avatar {
                img {
                    border-radius: var(--dui-border-radius-avatar);
                    background: var(--color-background);
                    width: 64px;
                    height: 64px;
                    text-align: center;
                }
            }

            .country-header {
                align-items: center;
            }

            .country-heading {
                display: flex;
                justify-content: flex-end;
                width: 100%;
            }

            .country-details {
                display: flex;
                flex-basis: 100%;
                flex-direction: column;
                gap: var(--dui-spacing-medium);

                >:not(:last-child) {
                    border-bottom: var(--dui-width-separator-thin) solid var(--dui-color-line-separator);
                }
            }
        }
    }
}