.combined-indicator-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--dui-spacing-extra-large);

    .themes {
        &.empty {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            min-height: 40vh;
        }
    }

    .thematic-container {
        display: flex;
        flex-direction: column;
        gap: var(--dui-spacing-medium);

        .thematic-header {
            background-color: transparent;
            gap: 0;
        }
    }
}
