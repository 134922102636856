.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    .heading {
        color: var(--tui-color-danger);
        font-size: var(--tui-font-size-super-large);
        font-weight: var(--tui-font-weight-bold);
    }

    .message {
        margin: var(--tui-spacing-large) 0;
        text-align: center;
        font-size: var(--tui-font-size-large);
    }
}
