.area-chart {
    flex-basis: 60%;
    flex-grow: 1;
    border-radius: 0;
    /* By default, ContainerCard has border-radius */
    box-shadow: 0px 0px 10px var(--dui-card-box-shadow);
}

.responsive-content {
    height: 12rem;

    .responsive-container {
        overflow: hidden;
    }
}

.heading-content {
    font-weight: bold;
}