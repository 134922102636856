.filters-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--dui-spacing-medium);

    .filters {
        display: inline-flex;
        flex-wrap: wrap;
        gap: var(--dui-spacing-medium);

        .clear-button {
            justify-self: flex-end;
            flex-shrink: 0;
        }

        .indicator-select-input {
            flex-grow: 1;
            max-width: 40rem;
        }
    }
}
