.message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.pending-message {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
