.table-wrapper {
    height: 42rem;
    overflow-x: auto;

    .overflow-container {
        overflow: unset;
    }

    .each-table-cell {
        border-right: 1px solid var(--dui-color-line-separator);
        padding: 0;

        .cell {
            padding: var(--dui-spacing-small);
            text-align: left;
        }
    }

    .header-row-style {
        border-right: 1px solid var(--dui-color-line-separator);
        background-color: var(--dui-column-background);
    }
}

.country-list-header-cell {
    background-color: var(--dui-column-background);
    padding: var(--dui-spacing-medium) !important;
}

.country-column-header {
    font-size: small;
}

.search-column-cell {
    padding: 0 var(--dui-spacing-medium);
    font-weight: bold;
}
