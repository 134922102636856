.dashboard-navigation {
    background-color: var(--color-background);

    .header-wrapper {
        background-color: var(--color-foreground);
    }

    .header-container {
        margin: auto;
        padding: var(--dui-spacing-extra-large);
        max-width: 100rem;

        .header-text {
            background-color: transparent;
            max-width: 60rem;
        }

        .dashboard-buttons {
            @media only screen and (max-width:610px) {
                align-content: flex-start;
                flex-direction: column;
            }

            display: flex;
            align-items: center;
            flex-shrink: 0;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: var(--dui-spacing-medium) var(--dui-spacing-extra-small);
            gap: var(--dui-spacing-medium);
        }

        .button {
            border-radius: var(--dui-border-radius-tag);
            border-color: var(--dui-tab-background-color);
            color: var(--dui-tab-background-color);
            font-size: var(--dui-font-size-large);
            font-weight: normal;
        }

        .dashboard-tab-list {
            @media only screen and (max-width:610px) {
                display: flex;
                flex-direction: column;
            }

            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            border: var(--width-separator-thin) solid var(--dui-tab-background-color);
            border-radius: var(--dui-border-radius-progress-bar);

            .default-tab-mode {
                padding: var(--dui-spacing-small) var(--dui-spacing-large);
                color: var(--dui-tab-background-color);
                font-weight: normal;

                &:not(:last-child) {
                    border-right: var(--width-separator-thin) solid var(--dui-tab-background-color);
                }

                @media only screen and (max-width:610px) {
                    &:not(:last-child) {
                        border-right: unset;
                    }

                    padding: var(--dui-spacing-medium);
                }
            }

            .active-tab {
                background-color: var(--dui-tab-background-color);
                color: var(--color-foreground);

                &:hover {
                    background-color: var(--dui-tab-background-color);
                }
            }
        }
    }

    .content {
        position: relative;
        flex-grow: 1;
        margin: var(--dui-spacing-extra-large) auto;
        padding: 0 var(--dui-spacing-extra-large);
        max-width: 100rem;
    }
}