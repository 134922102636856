.chip {
    --inner-spacing: var(--dui-spacing-extra-small);
    --outer-vertical-padding: calc(var(--dui-spacing-medium) - var(--inner-spacing));

    display: flex;
    align-items: center;
    border-radius: 2em;
    background-color: #e7e7e7;
    padding: 0  var(--dui-spacing-small);
    width: fit-content;
    color: var(--color-text);
    font-size: var(--dui-font-size-small);

    &.disabled {
        opacity: var(--dui-opacity-disabled-element);
    }

    /* &.accent {
        --color-background: var(--dui-color-accent);
    }

    &.danger {
        --color-background: var(--dui-color-danger);
    }

    &.primary {
        --color-background: var(--dui-color-primary);
    }

    &.success {
        --color-background: var(--dui-color-success);
    }

    &.warning {
        --color-background: var(--dui-color-warning);
    }

    &.light {
        --border-color: var(--dui-color-separator-light);
    }

    &.dark {
        --border-color: var(--dui-color-separator-dark);
    }

    &.inner-light {
        --color-text: var(--dui-color-text-button-light);
    }

    &.inner-dark {
        --color-text: var(--dui-color-text-button-dark);
    } */

    .icon {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        padding: var(--inner-spacing);
    }

    .label {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding: var(--inner-spacing);
    }

    .children {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding: var(--inner-spacing);
    }

    .action {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        padding: var(--inner-spacing);
    }
}