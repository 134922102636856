.progress-info {
    display: flex;
    flex-direction: column;
    padding: var(--dui-spacing-small) 0;

    .progress-title {
        display: flex;
        align-items: center;
        text-align: left;
        gap: var(--dui-spacing-small);
    }

    .progress-value-wrapper {
        display: flex;
        align-items: center;
        line-height: 1;
        gap: var(--dui-spacing-medium);

        .progress-bar-wrapper {
            display: flex;
            flex-grow: 1;
            border-radius: 20px;
            background-color: var(--dui-color-background-progress-bar-light);

            .progress-bar-style {
                transition: 200ms width ease-in-out;

                &:first-of-type {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }

                &:last-of-type {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        }

        .progress-value {
            font-size: var(--dui-font-size-medium);
        }
    }
}
