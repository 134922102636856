.map-modal {
    min-height: 16rem;

    .country-link-icon {
        color: var(--color-text-title);
    }

    .chart-container {
        box-shadow: 0px 0px;
        width: 99%;
        height: 26rem;
        overflow: hidden;

        .tooltip-card {
            display: flex;
            flex-direction: column;
            border: 1px solid var(--dui-color-separator);
            box-shadow: 0px 0px 4px var(--dui-card-box-shadow);
            background-color: var(--color-foreground);
            padding: var(--dui-spacing-small) var(--dui-spacing-medium);
            font-size: var(--dui-font-size-medium);
            gap: var(--dui-spacing-small);

            .tooltip-heading {
                font-weight: var(--dui-font-weight-bold);
            }

            .tooltip-content {
                color: var(--dui-color-description);
                font-size: var(--dui-font-size-small);
            }
        }
    }

    .sources {
        width: 100%;
    }
}

.backdrop {
    z-index: 2;
}

.modal-description {
    display: flex;
    align-items: baseline;
    gap: var(--dui-spacing-large);

    .country-case-data {
        color: var(--color-text-title);
        font-weight: var(--dui-font-weight-light);
    }

    .country-survey-date {
        color: var(--dui-color-watermark);
        font-weight: var(--dui-font-weight-light);
    }
}
