.thematic-filter-section {
    border: 1px solid var(--dui-color-separator);
    border-radius: var(--dui-border-radius-card);
    background-color: var(--dui-color-background);
    gap: var(--dui-spacing-large);

    .advanced-filters {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        padding: var(--dui-spacing-large);
        gap: var(--dui-spacing-large);

        .clear-button {
            flex-shrink: 0;
            padding: 0;
            justify-self: flex-end;

            &.empty {
                opacity: 0;
            }
        }
    }
}
