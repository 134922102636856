.topic-card {
    background-color: var(--dui-color-background);

    .heading {
        --spacing: 0;

        .topic-description {
            font-size: var(--dui-font-size-medium);
        }
    }

    .emergencies {
        display: flex;
        flex-direction: column;
        gap: var(--dui-spacing-medium);
    }
}
