.percentage-stats-card {
    display: flex;
    flex-shrink: 0;
    border-radius: 0;
    /* By default, ContainerCard has border-radius */
    box-shadow: 0px 0px 10px var(--dui-card-box-shadow);
    max-width: 20vw;

    @media only screen and (max-width:720px) {
        display: flex;
        flex-wrap: wrap;
        max-width: none;
    }

    .percentage-heading {
        font-weight: var(--dui-font-weight-bold);
    }

    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-text-secondary);
        font-size: var(--dui-font-size-super-large);
    }

    .value-and-sub-value {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        .value-text {
            color: var(--color-text-secondary);
            font-size: var(--dui-font-size-mega-large);
            font-weight: var(--dui-font-weight-bold);
        }

        .sub-value-text {
            padding: var(--dui-spacing-extra-small);
            font-size: var(--dui-font-size-medium);
            font-weight: var(--dui-font-weight-medium);
        }
    }

    .outbreak-card {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .pending-message {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
}