.overview-main {
    display: flex;
    flex-direction: column;
    gap: var(--dui-spacing-large);
    padding-bottom: var(--dui-spacing-large);

    .map-container {
        flex-grow: 1;
        border-radius: 0;
        /* By default, ContainerCard has border-radius */
        box-shadow: 0px 0px 10px var(--dui-card-box-shadow);

        .map-header-container {
            padding: var(--dui-spacing-large);
            overflow-y: auto;
        }

        .map-section-header {
            display: flex;
            flex-wrap: wrap;
        }

        .map-action-tabs {
            flex-shrink: 0;
            padding: var(--dui-spacing-large);
        }
    }

    /*
    FIX: Disabled for later
    .area-chart-box {
        display: flex;
        gap: var(--dui-spacing-extra-large);
    } */

    .dashboard-tab-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border: var(--width-separator-thin) solid var(--dui-tab-background-color);
        border-radius: var(--dui-border-radius-progress-bar);

        .default-tab-mode {
            padding: var(--dui-spacing-small) var(--dui-spacing-large);
        }

        .active-tab {
            background-color: var(--dui-tab-background-color);
            color: white;

            &:hover {
                background-color: var(--dui-tab-background-color);
            }
        }
    }
}