.card-info {
    display: flex;
    gap: var(--dui-spacing-large);

    .heading-content {
        font-weight: bold;
    }

    .global-stat-card {
        display: flex;
        flex-basis: 20%;
        border-radius: 0;
        /* By default, ContainerCard has border-radius */
        box-shadow: 0px 0px 10px var(--dui-card-box-shadow);
    }

    .trends-card {
        display: flex;
        flex-basis: calc(35% - var(--dui-spacing-small));
        border-radius: 0;
        /* By default, ContainerCard has border-radius */
        box-shadow: 0px 0px 10px var(--dui-card-box-shadow);
    }

    .regions-card {
        display: flex;
        flex-basis: calc(45% - var(--dui-spacing-small));
        border-radius: 0;
        /* By default, ContainerCard has border-radius */
        box-shadow: 0px 0px 10px var(--dui-card-box-shadow);
    }

    .responsive-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-height: 10rem;

        .responsive-container {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            overflow: hidden;
        }
    }

    .outbreak-legend-title-name {
        color: var(--color-outbreak-title-name);
        font-size: var(--dui-font-size-medium);
    }

    .percentage-card {
        flex-basis: calc(45% - var(--dui-spacing-large));
        flex-grow: 1;
        border-radius: 0;
        /* By default, ContainerCard has border-radius */
        box-shadow: 0px 0px 10px var(--dui-card-box-shadow);
        overflow-y: auto;

        .outbreak-card {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }

        .global-details {
            display: flex;
            gap: var(--dui-spacing-large);
            overflow-y: auto;

            .global-chart-container {
                display: flex;
                justify-content: center;

                .globalValue {
                    display: flex;
                    align-items: center;
                    flex-basis: calc(40% - var(--dui-spacing-extra-large));
                    justify-content: center;
                    color: var(--color-text-secondary);
                    font-size: var(--dui-font-size-mega-large);
                }

                .global-progress-bar {
                    flex-basis: calc(60% - var(--dui-spacing-extra-large));
                    flex-grow: 1;
                    padding: var(--dui-spacing-large);
                    height: 14rem;
                    overflow-y: hidden;
                    scrollbar-gutter: stable both-edges;

                    &:hover {
                        overflow-y: auto;
                    }
                }
            }
        }
    }
}