.modified-progress-bar {
    display: flex;

    .bar-name-container {
        display: flex;
        align-items: center;
        text-align: left;
        gap: var(--dui-spacing-small);

        .bar-name {
            display: inline-block;
            padding: 0;
            text-align: left;
            font-weight: var(--dui-font-weight-normal);

            .children {
                display: inline;
            }

            .indicator-name,
            .separator,
            .subvariable-name {
                display: inline;
                color: var(--dui-color-text-label);
                font-size: var(--dui-font-size-small);
            }

            .separator {
                padding: 0 var(--dui-spacing-extra-small);
            }
        }
    }

    .sub-value {
        align-self: end;
        color: var(--color-text-title);
        font-size: var(--dui-font-size-small);
    }
}
