.map-label {
    display: flex;
    flex-direction: column;
    border-radius: var(--dui-border-radius-card);
    background-color: rgba(255, 255, 255, 0.8);
    padding: var(--dui-spacing-small);
    min-width: 18rem;
    gap: var(--dui-spacing-extra-small);

    .bar {
        border-radius: var(--dui-border-radius-card);
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(123,151,168,1) 50%, rgba(2,56,88,1) 100%);
        height: 0.6rem;
    }

    .label-container {
        display: flex;
        justify-content: space-between;
    }
}
