:root {
    /*
     * Fonts
     */
    --dui-font-family-sans-serif: var(--font-family-sans-serif);
    --dui-font-family-heading: var(--font-family-sans-serif);

    --dui-font-size-super-small: 0.5rem;
    --dui-font-size-extra-small: 0.63rem;
    --dui-font-size-small: 0.75rem;
    --dui-font-size-medium: 0.875rem;
    --dui-font-size-large: 1rem;
    --dui-font-size-extra-large: 1.25rem;
    --dui-font-size-super-large: 2rem;
    --dui-font-size-mega-large: 3rem;
    --dui-font-size-ultra-large: 4.6rem;

    --dui-font-size-heading-extra-large: var(--dui-font-size-super-large);
    --dui-font-size-heading-large: 1.5rem;
    --dui-font-size-heading-medium: var(--dui-font-size-extra-large);
    --dui-font-size-heading-small: var(--dui-font-size-extra-large);
    --dui-font-size-heading-extra-small: var(--dui-font-size-large);

    --dui-font-weight-light: 200;
    --dui-font-weight-regular: 400;
    --dui-font-weight-medium: 500;
    --dui-font-weight-bold: 700;
    --dui-font-weight-heading: 900;

    /*
     * Dimensions
     */
    --dui-spacing-ultra-small: 0.1rem;
    --dui-spacing-extra-extra-small: 0.3rem;
    --dui-spacing-super-small: 0.14rem;
    --dui-spacing-extra-small: 0.26rem;
    --dui-spacing-small: 0.41rem;
    --dui-spacing-medium: 0.79rem;
    --dui-spacing-large: 1.17rem;
    --dui-spacing-extra-large: 1.83rem;
    --dui-spacing-super-large: 3.01rem;
    --dui-spacing-mega-large: 4.81rem;
    --dui-spacing-ultra-large: 6.89rem;

    --dui-width-separator-thin: 1px;
    --dui-width-separator-medium: 2px;
    --dui-width-separator-thick: 4px;
    --dui-width-separator-dense: 7px;

    --dui-width-active-border: 1.5625rem;

    --dui-opacity-watermark: 0.3;
    --dui-opacity-disabled-element: 0.4;
    --dui-saturation-disabled-element: 0.4;

    --dui-width-popup-tip: 1rem;
    --dui-offset-popup-tip: 0.625rem;
    --dui-height-popup-tip: 0.625rem;

    --dui-width-max-modal: 96vw;
    --dui-height-max-modal: 96vh;

    --dui-width-modal-extra-small: 360px;
    --dui-height-modal-extra-small: 240px;

    --dui-width-modal-small: 480px;
    --dui-height-modal-small: 360px;

    --dui-width-modal-medium: 640px;
    --dui-height-modal-medium: 560px;

    --dui-width-modal-large: 800px;
    --dui-height-modal-large: 720px;

    --dui-width-modal-cover: 96vw;
    --dui-height-modal-cover: 96vh;

    --dui-width-min-modal: 20rem;
    --dui-width-scrollbar: 0.5rem;

    --dui-width-calendar-date: 2.4rem;

    --dui-select-input-width-large: 580px;

    /* COLOR PALETTE */
    --dui-color-primary: rgb(65, 131, 196);
    --dui-color-accent: rgb(65, 131, 196);
    --dui-color-brand: rgb(25, 50, 86);
    --dui-color-brand-hover: #06179E;
    --dui-color-background-navbar: #08284B;
    --dui-tab-background-color: #08284B;

    --dui-color-text: rgb(100, 99, 99);
    --dui-filter-background-color: #EFF4FA;

    --dui-color-label: rgb(100, 99, 99);
    --dui-color-description: rgb(100, 99, 99);
    --dui-color-watermark: rgb(100, 99, 99);
    --dui-card-border-color: #f1f1f1;
    --dui-card-box-shadow: rgba(0, 0, 0, 0.08);

    --dui-color-background: #fbfbfb;
    --dui-color-background-information: var(--dui-color-background);
    --dui-color-background-progress-bar-light: #f2f2f2;
    --dui-color-progress-alt: #98A6B5;
    --dui-color-line-separator: #D9D9D9;
    --dui-column-background: #F2F2F2;
    --dui-high-indicator-cell-background: #eaeaea;

    --color-foreground: #ffffff;
    --color-background: #F5F5F5;
    --color-background-dark: #193256;
    --color-background-alt: #E8F9F1;
    --color-text-on-dark: #ffffff;
    --color-text: #333333;
    --color-text-secondary: #8DD2B1;
    --color-text-title: #2F9C67;
    --color-progress-bar: #FF800B;

    --color-outbreak-title-name: #4F4E4E;

    --color-indicator-high: #f4e8ff;
    --color-indicator-medium-high: #dcfce7;
    --color-indicator-medium-low: #fff4de;
    --color-indicator-low: #ffe2e6;

    --color-navbar: rgb(51, 64, 83);
    --color-text-on-navbar: rgba(255, 255, 255, .9);

    --color-primary: rgb(33, 105, 187);
    --color-accent: rgb(91, 192, 222);
    --color-danger: rgb(187, 33, 36);
    --color-success: rgb(75, 218, 138);
    --color-warning: rgb(240, 173, 78);

    /* COLOR SEPARATOR */
    --color-separator: rgb(39, 109, 184);
    --color-separator-light: rgba(79, 143, 191, 0.3);

    /* DIMENSIONS */
    --width-separator-thin: 1px;
    --width-separator-medium: 2px;
    --width-separator-thick: 4px;

    /* RADIUS */
    /* FIXME: should either be border-radius or radius-border or radius */
    --dui-radius-border-small: .12rem;
    --dui-radius-border-medium: .2rem;
    --dui-radius-popup-border: .2rem;
    --dui-radius-blur-shadow: .2rem;
    --dui-radius-modal: .2rem;

    --dui-radius-border-input-section: 0;
    --dui-radius-border-alerts: 0.24rem;

    --dui-radius-scrollbar-border: 0.25rem;

    --dui-border-radius-card: 0.5rem;
    --dui-border-radius-button: 0.5rem;
    --dui-border-radius-modal: 0.5rem;
    --dui-border-radius-tag: 1.5rem;
    --dui-border-radius-progress-bar: 1rem;
    --dui-border-radius-avatar: 50%;

    /*
     * TIMING
     */
    --dui-duration-transition-short: 100ms;
    --dui-duration-transition-medium: 200ms;
    --dui-duration-transition-long: 400ms;

    --dui-duration-delay-short: 150ms;
    --dui-duration-delay-medium: 250ms;
    --dui-duration-delay-long: 400ms;

}

.base {
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    color: var(--color-text);

    .header {
        background-color: var(--dui-color-background-navbar);

        .header-content {
            display: flex;
            margin: auto;
            padding: var(--dui-spacing-medium) var(--dui-spacing-extra-large);
            max-width: 100rem;
        }

        .left-container {
            flex-grow: 1;
            color: #ffffff;

            .logo {
                height: 2.5rem;
            }
        }

        .right-container {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            gap: var(--dui-spacing-large);

            .nav-item {
                color: #ffffff;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .footer {
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #000000;
        padding: var(--dui-spacing-large);
        gap: var(--dui-spacing-large);
        color: #ffffff;

        .footer-description {
            max-width: 60rem;
            font-size: var(--dui-font-size-medium);
        }

        .logo-container {
            display: flex;
            align-items: center;
            gap: var(--dui-spacing-medium);

            .logo {
                max-height: 2rem;
            }
        }

        .copyright {
            max-width: 60rem;
            font-size: var(--dui-font-size-medium);

            .links {
                display: inline-flex;
                align-items: center;
                gap: var(--dui-spacing-small);

                .link {
                    color: #ffffff;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .view {
        flex-grow: 1;
    }
}

a {
    text-decoration: none;
    color: var(--dui-color-accent);

    &:hover {
        color: var(--dui-color-brand);
    }

    &:visited {
        text-decoration: none;
    }
}

/* Unset font-size because drupal has set a font-size of 10px */
html {
    font-size: unset;
}
