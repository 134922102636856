.sources {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    gap: var(--dui-spacing-extra-small);

    .heading {
        color: unset;
    }

    &.mini {
        .heading {
            font-size: var(--dui-font-size-medium);
        }
    }

    &:not(.mini) {
        width: 40vw;

        @media only screen and (max-width:1440px) {
            width: auto;
        }
    }
}