.export-progress-popup {
    position: fixed;
    right: var(--dui-spacing-large);
    bottom: var(--dui-spacing-large);
    z-index: 100000;
    border: 1px solid var(--dui-color-brand);
    border-radius: 5px;
    background-color: var(--dui-color-foreground);
    padding: var(--dui-spacing-medium);

    .top-container {
        display: flex;
        justify-content: space-between;
    }

    .progress-bar {
        width: 20rem;
    }
}
