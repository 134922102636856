.sub-indicator-item {
    display: flex;
    flex-direction: column;

    .heading {
        color: var(--dui-color-text-label);
    }

    .indicator-list {
        display: flex;
        flex-wrap: wrap;
        gap: var(--dui-spacing-large);

        @media only screen and (max-width:610px) {
            flex-direction: column;
        }

        .progress-bar {
            flex-basis: 30%;
        }
    }

    &:not(:last-child) {
        border-bottom: var(--dui-width-separator-thin) solid var(--dui-color-separator);

        .indicator-list {
            padding-bottom: var(--dui-spacing-medium);
        }
    }
}