.pie-chart-wrapper {
    display: flex;
    flex-direction: column;
    opacity: 1;
    width: 18%;

    &.less-opacity {
        opacity: 0.2;
    }

    .pie-chart-header {
        align-self: center;
        font-size: var(--dui-font-size-small);
    }

    .pie-chart-holder {
        background-color: #ffffff;
    }

    .responsive-container {
        min-height: 7rem;
        overflow: visible;
    }
}
