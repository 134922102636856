.map-view-wrapper {
    display: flex;
    align-items: flex-start;

    @media only screen and (max-width:720px) {
        display: inline;
    }

    .map-container {
        position: relative;
        flex-grow: 1;
        padding-left: 0px;
        min-height: 42rem;

        .map-label-box {
            position: absolute;
            bottom: 2.5rem;
            left: 2rem;
        }
    }

    .progress-bar-container {
        flex-basis: 25%;
        flex-direction: column;
        flex-shrink: 0;

        .progress-content {
            display: flex;
            flex-direction: column;
            gap: var(--dui-spacing-large);
            
            .low-progress-box {
                min-height: 7rem;
            }

            .high-progress-box {
                min-height: 7rem;
            }

            .pending-message {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                height: 100%;
            }

            .progress-list-header {
                padding-bottom: var(--dui-spacing-medium);
            }

            .progress-list {
                font-size: small;

                .progress-item-border {
                    margin-top: var(--dui-spacing-medium);
                }
            }
        }
    }

    .label {
        font-weight: var(--dui-font-weight-bold);
    }

    .description {
        color: var(--dui-color-text-description);
        font-size: var(--dui-font-size-extra-small);
        font-weight: var(--dui-font-weight-regular);
    }
}