.chip-component {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    padding: var(--dui-spacing-large);
    gap: var(--dui-spacing-large);

    .chip-filter-header {
        font-size: var(--dui-font-size-small);
        font-weight: var(--dui-font-weight-bold);
    }

    .chip-collection {
        display: flex;
        flex-wrap: wrap;
        gap: var(--dui-spacing-medium);

        .chip-action-buttons {
            padding: var(--dui-spacing-extra-small);
        }

    }
}